import {ApolloClient, HttpLink} from "@apollo/client";
import fetch from "isomorphic-fetch";

import {cache} from "./cache";

//Configuración de cliente según la doc oficial
//https://www.gatsbyjs.com/plugins/gatsby-plugin-apollo/

const client = new ApolloClient({
    cache,
    // uri: "http://localhost:4000/graphql",
    link: new HttpLink({
        uri: "http://localhost:7071/%7Bsegments%7D",
        // uri: "http://localhost:4000/graphql",
        fetch,
    }),
    headers: {
        "Content-Type": "application/json",
    },
});

export default client;
