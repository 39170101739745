/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from "react";
import {ApolloProvider} from "@apollo/client";
import {Auth0Provider} from "@auth0/auth0-react";
import {navigate} from "gatsby";

import client from "./src/gatsby-plugin-apollo/client";
import {isBrowser} from "./src/utils";

const onRedirectCallback = (appState) => {
    // Use Gatsby's navigate method to replace the url
    navigate(appState?.returnTo || "/", {replace: true});
};

export const wrapRootElement = ({element}) => {
    return (
        <Auth0Provider
            audience={process.env.AUTH0_AUDIENCE}
            clientId={process.env.AUTH0_CLIENTID}
            domain={process.env.AUTH0_DOMAIN}
            redirectUri={isBrowser() && window.location.origin}
            scope="read:current_user"
            onRedirectCallback={onRedirectCallback}
        >
            <ApolloProvider client={client}>{element}</ApolloProvider>
        </Auth0Provider>
    );
};
