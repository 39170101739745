import {InMemoryCache, makeVar} from "@apollo/client";

import {
    activeProjectInitialState,
    calendarInitialState,
    methodsInitialState,
    projectsInitialState,
    userInitialState,
} from "./initialStates";

export const cache = new InMemoryCache({
    typePolicies: {
        User: {
            keyFields: ["email"],
        },
        Query: {
            fields: {
                user: {
                    read() {
                        return userVar();
                    },
                },
                projects: {
                    read() {
                        return projectsVar();
                    },
                },
                calendar: {
                    read() {
                        return calendarVar();
                    },
                },
                methods: {
                    read() {
                        return methodsVar();
                    },
                },
            },
        },
    },
});

export const userVar = makeVar(userInitialState);
export const projectsVar = makeVar(projectsInitialState);
export const calendarVar = makeVar(calendarInitialState);
export const methodsVar = makeVar(methodsInitialState);
