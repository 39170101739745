export const projectsInitialState = [
    {
        0: {
            id: "1",
            name: "Canal pluvial",
            description:
                "CANAL PLUVIAL NORTE Av. DE CIRCUNVALACIÓN - TRAMO: DISTRIBUIDOR ACCESO Av. 11 DE SEPTIEMBRE - PUENTE JOSÉ IGNACIO DÍAZ",
            ancestors: [],
            descendants: [1, 4],
            predecessors: [],
            successors: [],
            start: 1643684400000,
            finish: 1659322800000,
            duration: 180,
            planStart: 1641006000000,
            planFinish: 1654052400000,
            planDuration: 150,
            actualStart: 1643684400000,
            evmData: {
                valorGanado: {
                    x: [
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                    ],
                    y: [5, 15, 35, 50],
                },
                valorEstimado: {
                    x: [
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                        new Date(2022, 6, 1).getTime(),
                        new Date(2022, 7, 1).getTime(),
                    ],
                    y: [50, 60, 70, 75],
                },
                costoActual: {
                    x: [
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                    ],
                    y: [10, 20, 35, 55],
                },
                costoPrevisto: {
                    x: [
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                        new Date(2022, 6, 1).getTime(),
                        new Date(2022, 7, 1).getTime(),
                    ],
                    y: [55, 70, 75, 80],
                },
                valorPlaneado: {
                    x: [
                        new Date(2022, 0, 1).getTime(),
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                    ],
                    y: [10, 20, 40, 60, 70, 75],
                },
            },
        },
        1: {
            id: "1.1",
            name: "OVERHEAD",
            isBudget: false,
            isWork: false,
            qty: "",
            unit: "",
            ancestors: [0],
            descendants: [2],
            predecessors: [],
            successors: [],
            start: 1643684400000,
            finish: 1659322800000,
            duration: 180,
            planStart: 1641006000000,
            planFinish: 1654052400000,
            planDuration: 150,
            actualStart: 1643684400000,
            evmData: {
                valorGanado: {
                    x: [
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                    ],
                    y: [5, 15, 35, 50],
                },
                valorEstimado: {
                    x: [
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                        new Date(2022, 6, 1).getTime(),
                        new Date(2022, 7, 1).getTime(),
                    ],
                    y: [50, 60, 70, 75],
                },
                costoActual: {
                    x: [
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                    ],
                    y: [10, 20, 35, 55],
                },
                costoPrevisto: {
                    x: [
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                        new Date(2022, 6, 1).getTime(),
                        new Date(2022, 7, 1).getTime(),
                    ],
                    y: [55, 70, 75, 80],
                },
                valorPlaneado: {
                    x: [
                        new Date(2022, 0, 1).getTime(),
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                    ],
                    y: [10, 20, 40, 60, 70, 75],
                },
            },
        },
        2: {
            id: "1.1.1",
            name: "Administración de obra",
            isBudget: false,
            isWork: false,
            qty: "",
            unit: "",
            ancestors: [0, 1],
            descendants: [],
            predecessors: [],
            successors: [],
            start: 1643684400000,
            finish: 1659322800000,
            duration: 180,
            planStart: 1641006000000,
            planFinish: 1654052400000,
            planDuration: 150,
            actualStart: 1643684400000,
            evmData: {
                valorGanado: {
                    x: [
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                    ],
                    y: [5, 15, 35, 50],
                },
                valorEstimado: {
                    x: [
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                        new Date(2022, 6, 1).getTime(),
                        new Date(2022, 7, 1).getTime(),
                    ],
                    y: [50, 60, 70, 75],
                },
                costoActual: {
                    x: [
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                    ],
                    y: [10, 20, 35, 55],
                },
                costoPrevisto: {
                    x: [
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                        new Date(2022, 6, 1).getTime(),
                        new Date(2022, 7, 1).getTime(),
                    ],
                    y: [55, 70, 75, 80],
                },
                valorPlaneado: {
                    x: [
                        new Date(2022, 0, 1).getTime(),
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                    ],
                    y: [10, 20, 40, 60, 70, 75],
                },
            },
        },
        3: {
            id: "1.1.2",
            name: "Intereses, impuestos y margen",
            isBudget: false,
            isWork: false,
            qty: "",
            unit: "",
            ancestors: [0, 1],
            descendants: [],
            start: 1643684400000,
            finish: 1659322800000,
            duration: 180,
            planStart: 1641006000000,
            planFinish: 1654052400000,
            planDuration: 150,
            actualStart: 1643684400000,
        },
        4: {
            id: "1.2",
            name: "OBRAS PERMANENTES",
            isBudget: false,
            isWork: false,
            qty: "",
            unit: "",
            ancestors: [0],
            descendants: [5, 6],
            start: 1643684400000,
            finish: 1659322800000,
            duration: 180,
            planStart: 1641006000000,
            planFinish: 1654052400000,
            planDuration: 150,
            actualStart: 1643684400000,
            evmData: {
                valorGanado: {
                    x: [
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                    ],
                    y: [5, 15, 35, 50],
                },
                valorEstimado: {
                    x: [
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                        new Date(2022, 6, 1).getTime(),
                        new Date(2022, 7, 1).getTime(),
                    ],
                    y: [50, 60, 70, 75],
                },
                costoActual: {
                    x: [
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                    ],
                    y: [10, 20, 35, 55],
                },
                costoPrevisto: {
                    x: [
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                        new Date(2022, 6, 1).getTime(),
                        new Date(2022, 7, 1).getTime(),
                    ],
                    y: [55, 70, 75, 80],
                },
                valorPlaneado: {
                    x: [
                        new Date(2022, 0, 1).getTime(),
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                    ],
                    y: [10, 20, 40, 60, 70, 75],
                },
            },
        },
        5: {
            id: "1.2.1",
            name: "Movilización",
            isBudget: true,
            isWork: true,
            qty: 1,
            unit: "Gl",
            ancestors: [0, 4],
            descendants: [],
            start: 1643684400000,
            finish: 1659322800000,
            duration: 180,
            planStart: 1641006000000,
            planFinish: 1654052400000,
            planDuration: 150,
            actualStart: 1643684400000,
            evmData: {
                valorGanado: {
                    x: [
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                    ],
                    y: [5, 15, 35, 50],
                },
                valorEstimado: {
                    x: [
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                        new Date(2022, 6, 1).getTime(),
                        new Date(2022, 7, 1).getTime(),
                    ],
                    y: [50, 60, 70, 75],
                },
                costoActual: {
                    x: [
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                    ],
                    y: [10, 20, 35, 55],
                },
                costoPrevisto: {
                    x: [
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                        new Date(2022, 6, 1).getTime(),
                        new Date(2022, 7, 1).getTime(),
                    ],
                    y: [55, 70, 75, 80],
                },
                valorPlaneado: {
                    x: [
                        new Date(2022, 0, 1).getTime(),
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                    ],
                    y: [10, 20, 40, 60, 70, 75],
                },
            },
        },
        6: {
            id: "1.2.2",
            name: "PRGS (0, 0+313)",
            isBudget: false,
            isWork: false,
            qty: "",
            unit: "",
            ancestors: [0, 4],
            descendants: [7, 8, 9],
            start: 1643684400000,
            finish: 1659322800000,
            duration: 180,
            planStart: 1641006000000,
            planFinish: 1654052400000,
            planDuration: 150,
            actualStart: 1643684400000,
            evmData: {
                valorGanado: {
                    x: [
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                    ],
                    y: [5, 15, 35, 50],
                },
                valorEstimado: {
                    x: [
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                        new Date(2022, 6, 1).getTime(),
                        new Date(2022, 7, 1).getTime(),
                    ],
                    y: [50, 60, 70, 75],
                },
                costoActual: {
                    x: [
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                    ],
                    y: [10, 20, 35, 55],
                },
                costoPrevisto: {
                    x: [
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                        new Date(2022, 6, 1).getTime(),
                        new Date(2022, 7, 1).getTime(),
                    ],
                    y: [55, 70, 75, 80],
                },
                valorPlaneado: {
                    x: [
                        new Date(2022, 0, 1).getTime(),
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                    ],
                    y: [10, 20, 40, 60, 70, 75],
                },
            },
        },
        7: {
            id: "1.2.2.1",
            name: "Excavación",
            isBudget: true,
            isWork: true,
            qty: 2500,
            unit: "m3",
            ancestors: [0, 4, 6],
            descendants: [],
            start: 1643684400000,
            finish: 1659322800000,
            duration: 180,
            planStart: 1641006000000,
            planFinish: 1654052400000,
            planDuration: 150,
            actualStart: 1643684400000,
            evmData: {
                valorGanado: {
                    x: [
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                    ],
                    y: [5, 15, 35, 50],
                },
                valorEstimado: {
                    x: [
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                        new Date(2022, 6, 1).getTime(),
                        new Date(2022, 7, 1).getTime(),
                    ],
                    y: [50, 60, 70, 75],
                },
                costoActual: {
                    x: [
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                    ],
                    y: [10, 20, 35, 55],
                },
                costoPrevisto: {
                    x: [
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                        new Date(2022, 6, 1).getTime(),
                        new Date(2022, 7, 1).getTime(),
                    ],
                    y: [55, 70, 75, 80],
                },
                valorPlaneado: {
                    x: [
                        new Date(2022, 0, 1).getTime(),
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                    ],
                    y: [10, 20, 40, 60, 70, 75],
                },
            },
        },
        8: {
            id: "1.2.2.2",
            name: "Hormigón H-8 de limpieza",
            isBudget: true,
            isWork: true,
            qty: 80,
            unit: "m3",
            ancestors: [0, 4, 6],
            descendants: [],
            start: 1643684400000,
            finish: 1659322800000,
            duration: 180,
            planStart: 1641006000000,
            planFinish: 1654052400000,
            planDuration: 150,
            actualStart: 1643684400000,
            evmData: {
                valorGanado: {
                    x: [
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                    ],
                    y: [5, 15, 35, 50],
                },
                valorEstimado: {
                    x: [
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                        new Date(2022, 6, 1).getTime(),
                        new Date(2022, 7, 1).getTime(),
                    ],
                    y: [50, 60, 70, 75],
                },
                costoActual: {
                    x: [
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                    ],
                    y: [10, 20, 35, 55],
                },
                costoPrevisto: {
                    x: [
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                        new Date(2022, 6, 1).getTime(),
                        new Date(2022, 7, 1).getTime(),
                    ],
                    y: [55, 70, 75, 80],
                },
                valorPlaneado: {
                    x: [
                        new Date(2022, 0, 1).getTime(),
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                    ],
                    y: [10, 20, 40, 60, 70, 75],
                },
            },
        },
        9: {
            id: "1.2.2.3",
            name: "Hormigón armado H-25",
            isBudget: true,
            isWork: true,
            qty: 1000,
            unit: "m3",
            ancestors: [0, 4, 6],
            descendants: [],
            start: 1643684400000,
            finish: 1659322800000,
            duration: 180,
            planStart: 1641006000000,
            planFinish: 1654052400000,
            planDuration: 150,
            actualStart: 1643684400000,
            evmData: {
                valorGanado: {
                    x: [
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                    ],
                    y: [5, 15, 35, 50],
                },
                valorEstimado: {
                    x: [
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                        new Date(2022, 6, 1).getTime(),
                        new Date(2022, 7, 1).getTime(),
                    ],
                    y: [50, 60, 70, 75],
                },
                costoActual: {
                    x: [
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                    ],
                    y: [10, 20, 35, 55],
                },
                costoPrevisto: {
                    x: [
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                        new Date(2022, 6, 1).getTime(),
                        new Date(2022, 7, 1).getTime(),
                    ],
                    y: [55, 70, 75, 80],
                },
                valorPlaneado: {
                    x: [
                        new Date(2022, 0, 1).getTime(),
                        new Date(2022, 1, 1).getTime(),
                        new Date(2022, 2, 1).getTime(),
                        new Date(2022, 3, 1).getTime(),
                        new Date(2022, 4, 1).getTime(),
                        new Date(2022, 5, 1).getTime(),
                    ],
                    y: [10, 20, 40, 60, 70, 75],
                },
            },
        },
        10: {
            id: "1.2.3",
            name: "PRGS (0+313, 0+641)",
            isBudget: false,
            isWork: false,
            qty: "",
            unit: "",
            ancestors: [0, 4],
            descendants: [11, 12, 13],
            start: 1643684400000,
            finish: 1659322800000,
            duration: 180,
            planStart: 1641006000000,
            planFinish: 1654052400000,
            planDuration: 150,
            actualStart: 1643684400000,
        },
        11: {
            id: "1.2.3.1",
            name: "Excavación",
            isBudget: true,
            isWork: true,
            qty: 2500,
            unit: "m3",
            ancestors: [0, 4, 10],
            descendants: [],
            start: 1643684400000,
            finish: 1659322800000,
            duration: 180,
            planStart: 1641006000000,
            planFinish: 1654052400000,
            planDuration: 150,
            actualStart: 1643684400000,
        },
        12: {
            id: "1.2.3.2",
            name: "Hormigón H-8 de limpieza",
            isBudget: true,
            isWork: true,
            qty: 80,
            unit: "m3",
            ancestors: [0, 4, 10],
            descendants: [],
            start: 1643684400000,
            finish: 1659322800000,
            duration: 180,
            planStart: 1641006000000,
            planFinish: 1654052400000,
            planDuration: 150,
            actualStart: 1643684400000,
        },
        13: {
            id: "1.2.3.3",
            name: "Hormigón armado H-25",
            isBudget: true,
            isWork: true,
            qty: 1000,
            unit: "m3",
            ancestors: [0, 4, 10],
            descendants: [],
            start: 1643684400000,
            finish: 1659322800000,
            duration: 180,
            planStart: 1641006000000,
            planFinish: 1654052400000,
            planDuration: 150,
            actualStart: 1643684400000,
        },
    },
    /* {
        id: 1,
        name: "Proyecto 1",
        wbs: {
            0: {
                name: "Proyecto 1",
                ancestors: [],
                descendants: [1, 3],
            },
            1: {
                name: "Item 1",
                qty: 100,
                unit: "m3",
                duration: 10,
                ancestors: [0],
                descendants: [2],
            },
            2: {
                name: "Item 2",
                qty: 100,
                unit: "m3",
                duration: 10,
                ancestors: [0, 1],
                descendants: [],
            },
            3: {
                name: "Item 3",
                qty: 100,
                unit: "m3",
                duration: 10,
                ancestors: [0],
                descendants: [],
            },
        },
        evmData: {
            //Actual start & actual finish
            valorGanado: {
                x: [
                    new Date(2022, 1, 1).getTime(),
                    new Date(2022, 2, 1).getTime(),
                    new Date(2022, 3, 1).getTime(),
                    new Date(2022, 4, 1).getTime(),
                ],
                y: [5, 15, 35, 50],
            },
            //start & finish
            valorEstimado: {
                x: [
                    new Date(2022, 4, 1).getTime(),
                    new Date(2022, 5, 1).getTime(),
                    new Date(2022, 6, 1).getTime(),
                    new Date(2022, 7, 1).getTime(),
                ],
                y: [50, 60, 70, 75],
            },
            costoActual: {
                x: [
                    new Date(2022, 1, 1).getTime(),
                    new Date(2022, 2, 1).getTime(),
                    new Date(2022, 3, 1).getTime(),
                    new Date(2022, 4, 1).getTime(),
                ],
                y: [10, 20, 35, 55],
            },
            costoPrevisto: {
                x: [
                    new Date(2022, 4, 1).getTime(),
                    new Date(2022, 5, 1).getTime(),
                    new Date(2022, 6, 1).getTime(),
                    new Date(2022, 7, 1).getTime(),
                ],
                y: [55, 70, 75, 80],
            },

            //baselineStart & baselineFinish
            valorPlaneado: {
                x: [
                    new Date(2022, 0, 1).getTime(),
                    new Date(2022, 1, 1).getTime(),
                    new Date(2022, 2, 1).getTime(),
                    new Date(2022, 3, 1).getTime(),
                    new Date(2022, 4, 1).getTime(),
                    new Date(2022, 5, 1).getTime(),
                ],
                y: [10, 20, 40, 60, 70, 75],
            },
        },
    },
    {
        id: 2,
        name: "Proyecto 2",
        evmData: {
            valorGanado: {
                x: [
                    new Date(2022, 2, 1).getTime(),
                    new Date(2022, 3, 1).getTime(),
                    new Date(2022, 4, 1).getTime(),
                    new Date(2022, 5, 1).getTime(),
                ],
                y: [5, 15, 35, 50],
            },
            valorEstimado: {
                x: [
                    new Date(2022, 5, 1).getTime(),
                    new Date(2022, 6, 1).getTime(),
                    new Date(2022, 7, 1).getTime(),
                    new Date(2022, 8, 1).getTime(),
                ],
                y: [50, 60, 70, 75],
            },
            costoActual: {
                x: [
                    new Date(2022, 2, 1).getTime(),
                    new Date(2022, 3, 1).getTime(),
                    new Date(2022, 4, 1).getTime(),
                    new Date(2022, 5, 1).getTime(),
                ],
                y: [10, 20, 35, 55],
            },
            costoPrevisto: {
                x: [
                    new Date(2022, 5, 1).getTime(),
                    new Date(2022, 6, 1).getTime(),
                    new Date(2022, 7, 1).getTime(),
                    new Date(2022, 8, 1).getTime(),
                ],
                y: [55, 70, 75, 80],
            },
            valorPlaneado: {
                x: [
                    new Date(2022, 1, 1).getTime(),
                    new Date(2022, 2, 1).getTime(),
                    new Date(2022, 3, 1).getTime(),
                    new Date(2022, 4, 1).getTime(),
                    new Date(2022, 5, 1).getTime(),
                    new Date(2022, 6, 1).getTime(),
                ],
                y: [10, 20, 40, 60, 70, 75],
            },
        },
    },
    {
        id: 3,
        name: "Proyecto 3",
        evmData: {
            valorGanado: {
                x: [
                    new Date(2022, 3, 1).getTime(),
                    new Date(2022, 4, 1).getTime(),
                    new Date(2022, 5, 1).getTime(),
                    new Date(2022, 6, 1).getTime(),
                ],
                y: [5, 15, 35, 50],
            },
            valorEstimado: {
                x: [
                    new Date(2022, 6, 1).getTime(),
                    new Date(2022, 7, 1).getTime(),
                    new Date(2022, 8, 1).getTime(),
                    new Date(2022, 9, 1).getTime(),
                ],
                y: [50, 60, 70, 75],
            },
            costoActual: {
                x: [
                    new Date(2022, 3, 1).getTime(),
                    new Date(2022, 4, 1).getTime(),
                    new Date(2022, 5, 1).getTime(),
                    new Date(2022, 6, 1).getTime(),
                ],
                y: [10, 20, 35, 55],
            },
            costoPrevisto: {
                x: [
                    new Date(2022, 6, 1).getTime(),
                    new Date(2022, 7, 1).getTime(),
                    new Date(2022, 8, 1).getTime(),
                    new Date(2022, 9, 1).getTime(),
                ],
                y: [55, 70, 75, 80],
            },
            valorPlaneado: {
                x: [
                    new Date(2022, 2, 1).getTime(),
                    new Date(2022, 3, 1).getTime(),
                    new Date(2022, 4, 1).getTime(),
                    new Date(2022, 5, 1).getTime(),
                    new Date(2022, 6, 1).getTime(),
                    new Date(2022, 7, 1).getTime(),
                ],
                y: [10, 20, 40, 60, 70, 75],
            },
        },
    },
    {
        id: 4,
        name: "Proyecto 4",
        evmData: {
            valorGanado: {
                x: [
                    new Date(2022, 4, 1).getTime(),
                    new Date(2022, 5, 1).getTime(),
                    new Date(2022, 6, 1).getTime(),
                    new Date(2022, 7, 1).getTime(),
                ],
                y: [5, 15, 35, 50],
            },
            valorEstimado: {
                x: [
                    new Date(2022, 7, 1).getTime(),
                    new Date(2022, 8, 1).getTime(),
                    new Date(2022, 9, 1).getTime(),
                    new Date(2022, 10, 1).getTime(),
                ],
                y: [50, 60, 70, 75],
            },
            costoActual: {
                x: [
                    new Date(2022, 4, 1).getTime(),
                    new Date(2022, 5, 1).getTime(),
                    new Date(2022, 6, 1).getTime(),
                    new Date(2022, 7, 1).getTime(),
                ],
                y: [10, 20, 35, 55],
            },
            costoPrevisto: {
                x: [
                    new Date(2022, 7, 1).getTime(),
                    new Date(2022, 8, 1).getTime(),
                    new Date(2022, 9, 1).getTime(),
                    new Date(2022, 10, 1).getTime(),
                ],
                y: [55, 70, 75, 80],
            },
            valorPlaneado: {
                x: [
                    new Date(2022, 3, 1).getTime(),
                    new Date(2022, 4, 1).getTime(),
                    new Date(2022, 5, 1).getTime(),
                    new Date(2022, 6, 1).getTime(),
                    new Date(2022, 7, 1).getTime(),
                    new Date(2022, 8, 1).getTime(),
                ],
                y: [10, 20, 40, 60, 70, 75],
            },
        },
    },
    {
        id: 5,
        name: "Proyecto 5",
        evmData: {
            valorGanado: {
                x: [
                    new Date(2022, 1, 1).getTime(),
                    new Date(2022, 2, 1).getTime(),
                    new Date(2022, 3, 1).getTime(),
                    new Date(2022, 4, 1).getTime(),
                ],
                y: [5, 15, 35, 50],
            },
            valorEstimado: {
                x: [
                    new Date(2022, 4, 1).getTime(),
                    new Date(2022, 5, 1).getTime(),
                    new Date(2022, 6, 1).getTime(),
                    new Date(2022, 7, 1).getTime(),
                ],
                y: [50, 60, 70, 75],
            },
            costoActual: {
                x: [
                    new Date(2022, 1, 1).getTime(),
                    new Date(2022, 2, 1).getTime(),
                    new Date(2022, 3, 1).getTime(),
                    new Date(2022, 4, 1).getTime(),
                ],
                y: [10, 20, 35, 55],
            },
            costoPrevisto: {
                x: [
                    new Date(2022, 4, 1).getTime(),
                    new Date(2022, 5, 1).getTime(),
                    new Date(2022, 6, 1).getTime(),
                    new Date(2022, 7, 1).getTime(),
                ],
                y: [55, 70, 75, 80],
            },
            valorPlaneado: {
                x: [
                    new Date(2022, 0, 1).getTime(),
                    new Date(2022, 1, 1).getTime(),
                    new Date(2022, 2, 1).getTime(),
                    new Date(2022, 3, 1).getTime(),
                    new Date(2022, 4, 1).getTime(),
                    new Date(2022, 5, 1).getTime(),
                ],
                y: [10, 20, 40, 60, 70, 75],
            },
        },
    },
    {
        id: 6,
        name: "Proyecto 6",
        evmData: {
            valorGanado: {
                x: [
                    new Date(2022, 1, 1).getTime(),
                    new Date(2022, 2, 1).getTime(),
                    new Date(2022, 3, 1).getTime(),
                    new Date(2022, 4, 1).getTime(),
                ],
                y: [5, 15, 35, 50],
            },
            valorEstimado: {
                x: [
                    new Date(2022, 4, 1).getTime(),
                    new Date(2022, 5, 1).getTime(),
                    new Date(2022, 6, 1).getTime(),
                    new Date(2022, 7, 1).getTime(),
                ],
                y: [50, 60, 70, 75],
            },
            costoActual: {
                x: [
                    new Date(2022, 1, 1).getTime(),
                    new Date(2022, 2, 1).getTime(),
                    new Date(2022, 3, 1).getTime(),
                    new Date(2022, 4, 1).getTime(),
                ],
                y: [10, 20, 35, 55],
            },
            costoPrevisto: {
                x: [
                    new Date(2022, 4, 1).getTime(),
                    new Date(2022, 5, 1).getTime(),
                    new Date(2022, 6, 1).getTime(),
                    new Date(2022, 7, 1).getTime(),
                ],
                y: [55, 70, 75, 80],
            },
            valorPlaneado: {
                x: [
                    new Date(2022, 0, 1).getTime(),
                    new Date(2022, 1, 1).getTime(),
                    new Date(2022, 2, 1).getTime(),
                    new Date(2022, 3, 1).getTime(),
                    new Date(2022, 4, 1).getTime(),
                    new Date(2022, 5, 1).getTime(),
                ],
                y: [10, 20, 40, 60, 70, 75],
            },
        },
    }, */
];

export let userInitialState = {
    name: "",
    surname: "",
    email: "",
    picture: "",
    token: "",
};

export let calendarInitialState = [
    {
        name: "Standard",
        shifts: [
            {
                name: "turnito Standar",
                recurrenceDays: 31,
                start: 545,
                end: 800,
            },
            {
                name: "turnito Standar",
                recurrenceDays: 31,
                start: 840,
                end: 1080,
            },
            {
                name: "sabado",
                recurrenceDays: 32,
                start: 540,
                end: 780,
            },
        ],
    },
    {
        name: "24hs",
        shifts: [
            {
                name: "turnito 24hs",
                recurrenceDays: 31,
                start: 540,
                end: 780,
            },
            {
                name: "turnito 24hs",
                recurrenceDays: 31,
                start: 840,
                end: 1080,
            },
            {
                name: "turnito 24hs",
                recurrenceDays: 31,
                start: 540,
                end: 780,
            },
        ],
    },
];
export let methodsInitialState = [
    {
        name: "method0",
        items: {
            1: {
                name: "",
                quantity: 0,
                unit: "",
                ancestors: [],
                descendants: [],
                predecessors: [],
                sucessors: [],
                start: 0,
                finish: 0,
                correctedStart: 0,
                correctedFinish: 0,
                duration: 0,
                work: 0,
            },
            2: {
                name: "",
                quantity: 0,
                unit: "",
                ancestors: [],
                descendants: [],
                predecessors: [],
                sucessors: [],
                start: 0,
                finish: 0,
                correctedStart: 0,
                correctedFinish: 0,
                duration: 0,
                work: 0,
            },
        },
    },
    {
        name: "method1",
        items: {
            1: {
                name: "",
                quantity: 0,
                unit: "",
                ancestors: [],
                descendants: [],
                predecessors: [],
                sucessors: [],
                start: 0,
                finish: 0,
                correctedStart: 0,
                correctedFinish: 0,
                duration: 0,
                work: 0,
            },
        },
    },
];
